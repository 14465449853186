import React, { useState } from 'react';
import './Contacto.css';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';
import emailjs from 'emailjs-com';

const Contacto = () => {
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [sent, setSent] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Parámetros a enviar con EmailJS
    const templateParams = {
      from_name: name,
      to_name: 'Soluciones Digitales Chile',  // Destinatario visible
      message: message,
      reply_to: email,
    };
    

    // Enviar el correo utilizando EmailJS
    emailjs.send('service_hjtczth', 'template_hqqkut8', templateParams, 'OtVefZRXKM3KBu2ye')
    .then((response) => {
        console.log('Correo enviado con éxito!', response.status, response.text);
        setSent(true);  // Mostrar un mensaje de éxito
      }, (err) => {
        console.error('Error al enviar el correo', err);
      });
  };

  return (
    <div>
      <Navbar />
      <section className="contactanos-section-contact">
        <div className="container-contact">
          <div className="contactanos-content-contact">
            <div className="contactanos-title-contact">
              <h1>Contáctanos</h1>
            </div>
            <div className="contactanos-body-contact">
              <div className="contactanos-image-contact">
                <img src="img/contact-ceos.webp" alt="Personas trabajando" />
              </div>
              <div className="form-card-contact">
                <h2>Escríbenos Tus Dudas</h2>
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    placeholder="Tu nombre..."
                    className="form-input-contact1"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  <input
                    type="text"
                    placeholder="Mensaje..."
                    className="form-input-contact2"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  />
                  <input
                    type="email"
                    placeholder="Ingresa tu email..."
                    className="email-input-contact"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <button type="submit" className="submit-button-contact">Enviar</button>
                </form>
                {sent && <p className='sent-message'>¡Correo enviado con éxito!</p>}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Contacto;
