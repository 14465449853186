import React from "react";
import '../Footer/Footer.css';

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-content">
                <div className="footer-section">
                    <img src={'img/10.webp'} alt="Person" className="person-image" />
                </div>
                <div className="footer-section about">
                    <h2>J&J LTDA.</h2>
                    <p>Sociedad multidisciplinaria en búsqueda de profesionales y especialistas.</p>
                    <button className="apply-button">Postula Aquí</button>
                </div>
                <div className="footer-section links">
                    <h3>EMPRESA</h3>
                    <ul>
                        <li><a href="#">Acerca de</a></li>
                        <li><a href="#">Desempeño</a></li>
                        <li><a href="#">Equipos</a></li>
                        <li><a href="#">Líderes</a></li>
                    </ul>
                </div>
                <div className="footer-section links">
                    <h3>AYUDA</h3>
                    <ul>
                        <li><a href="#">Soporte Técnico</a></li>
                        <li><a href="#">Problema con Pagos</a></li>
                        <li><a href="#">Términos y Condiciones</a></li>
                        <li><a href="#">Políticas de Privacidad</a></li>
                    </ul>
                </div>
                <div className="footer-section links">
                    <h3>SOCIAL</h3>
                    <ul>
                        <li><a href="#">LinkedIn</a></li>
                        <li><a href="#">Facebook</a></li>
                        <li><a href="#">Instagram</a></li>
                        <li><a href="#">TikTok</a></li>
                    </ul>
                </div>
            </div>
            <div className="payment-logos">
                <div className="title-column">
                    <img src="img/logo3-empresa.webp" alt="Logo" className="logo-empresa-footer" />
                </div>
            </div>

        </div>
    );
}

export default Footer;
